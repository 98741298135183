.ContainerBranch {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.Title {
  margin: 16px 5px 0;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.ItemContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;
}

.KeyCenter {
  margin: 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Schedule {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.State {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.CenterContainer {
  margin-top: 24px;
  display: flex;
  width: 100%;
  max-width: 260px;
  padding: 16px 27px 11px;
  border-radius: 19px;
  box-shadow: 4px 8px 8px 0 #d2ddff;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
}

.First {
  margin-top: 16px;
}

.Center {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 100%;
}

.Address {
  margin: 4px 0 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.Icon {
  height: 40px;
  width: 40px;
}

.TitleName {
  margin: 0;
  font-family: QuincyCF;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

@media(max-width: 350px){
  
  .CenterContainer {
    max-width: 240px;
  }

  .CenterContainer {
    padding: 16px 20px 11px;
  }
}


@media(min-width: 700px){
  .State {
    flex-direction: row;
  }

  .CenterContainer {
    max-width: 278px;
    margin-top: 16px;
  }

  .ItemContainer {
    margin-top: 55px;
  }

  .ContainerBranch {
    margin-bottom: 83px;
  }

}

