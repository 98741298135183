.ConfirmContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 350px;
  border-radius: 18px;
  border: solid 1px #707070;
  background-color: #ffffff;
  margin: 140px 0 30px;
}

.Title {
  margin: 62px 5px 0;
  font-family: ModernEraBold;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Dates {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Desc {
  margin: 23px 0 0;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}


.CenterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  background-color: #ebebeb;
  outline: none;
  margin-top: 30px;
  padding: 33px 22px;
  border-radius: 13.5px;
}

.AreUSure {
  margin: 41px 17px 0;
  font-family: ModernEra;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Reschedule {
  padding: 16px 80px;
  margin-top: 41px;
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0 rgba(0, 63, 255, 0.24);
  background-color: #0752f9;
  font-family: ModernEra;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  outline: none;
}

.TextFooter {
  margin: 36px 0;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}

.Item {
  display: flex;
}

.Icon {
  width: 20.8px;
}


.InfoDate {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Address {
  margin: 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Name {
  margin:0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Center {
  margin: 0;
  justify-content: center !important;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Error {
	color: red;
	margin: 20px 10px;
  font-family: "ModernEra";
  text-align: center;
}

.Close {
  display: flex;
  align-self: flex-end;
  margin: 23.5px 20px 0 0;
  cursor: pointer;
  outline: none;
}

.ContainerLoader {
  margin-top: 30px;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.ItemSecond {
  display: flex;
  margin-top: 24px;
}

@media (max-width: 350px) {
  .ConfirmContainer {
    max-width: 300px;
    margin: 280px 0 30px;
  }

  .CenterContainer {
    max-width: 230px;
  }
}

@media (min-width: 700px) {
  .ConfirmContainer {
    max-width: 450px;
    margin: 80px 0 30px;
  }

  .CenterContainer {
    max-width: 330px;
  }
}